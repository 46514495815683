<template>
  <div>
        <div ref="toolbar">
    <span class="ql-formats">
      <select class="ql-size">
        <option selected>Default</option>
        <option value="24px">24px</option>
        <option value="48px">48px</option>
        <option value="100px">100px</option>
        <option value="200px">200px</option>
      </select>

      <!-- Color picker -->
      <!--
	<select class="ql-color">
    <option value="rgb(0, 0, 0)" />
    <option value="rgb(230, 0, 0)" />
    <option value="rgb(255, 153, 0)" />
    <option value="rgb(255, 255, 0)" />
    <option value="rgb(0, 138, 0)" />
    <option value="rgb(0, 102, 204)" />
    <option value="rgb(153, 51, 255)" />
    <option value="rgb(255, 255, 255)" />
    <option value="rgb(250, 204, 204)" />
    <option value="rgb(255, 235, 204)" />
    <option value="rgb(204, 224, 245)" />
    <option value="rgb(235, 214, 255)" />
    <option value="rgb(187, 187, 187)" />
    <option value="rgb(102, 185, 102)" />
	</select>
  -->
    </span>
    <span class="ql-formats">
      <select class="ql-line-height" style="z-index: 9999">
        <option value="1.0">1.0</option>
        <option value="1.2">1.2</option>
        <option value="1.5">1.5</option>
        <option value="1.6">1.6</option>
        <option value="1.8">1.8</option>
        <option value="2.0">2.0</option>
        <option value="2.4">2.4</option>
        <option value="2.8">2.8</option>
        <option value="3.0">3.0</option>
        <option value="4.0">4.0</option>
        <option value="5.0">5.0</option>
        <!--
          <option
              v-for="(item, index) in lineHeight"
              :key="index"
              :value="item"
              :selected="item === val"
          >{{ item }}</option>
          -->
      </select>
    </span>
          <span class="ql-formats">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
    </span>
          <span class="ql-formats">
      <select class="ql-color"></select>
      <select class="ql-background"></select>
    </span>
          <span class="ql-formats">
      <button class="ql-header" value="1"></button>
      <button class="ql-header" value="2"></button>
      <button class="ql-blockquote"></button>
      <button class="ql-code-block"></button>
    </span>
          <span class="ql-formats">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <button class="ql-indent" value="-1"></button>
      <button class="ql-indent" value="+1"></button>
    </span>
          <span class="ql-formats">
      <button class="ql-direction" value="rtl"></button>
      <select class="ql-align"></select>
    </span>
          <span class="ql-formats">
      <button class="ql-link"></button>
      <button class="ql-formula"></button>
    </span>
          <span class="ql-formats">
      <button class="ql-clean"></button>
    </span>
          <slot name="customButton">
            <!--
            <span class="ql-formats">
              <button class="ql-customControl" style="width: 100px; text-align: left; display: contents;"><i class="fa fa-building"></i> Add Sponsor</button>
            </span>
            -->

            <!-- Add custom button(sponsor button) below on the place where this component is required ...to be uncommented when required
            <template v-slot:customButton>
              <span class="ql-formats">
                  <button class="ql-customControl" style="width: 100px; text-align: left; display: contents;"><i class="fa fa-building"></i> Add Sponsor</button>
              </span>
            </template>
            -->
          </slot>
        </div>
        <div class="editor" ref="editor" v-html="content" :style="color ? {'background-color': color} : ''" style="resize: vertical; overflow: auto">
        </div>
        <!-- <b-button variant="primary" @click="handleSavingContent" class="mt-3">Save</b-button> -->
       <!-- <text-editor :editorToolbar="toolbarOpts.modules.toolbar.container"></text-editor> -->
  </div>
</template>

<script>
  //import { VueEditor, Quill  } from 'vue2-editor'
  import Quill from 'quill'
  var fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = ['24px', '48px', '100px', '200px'];
  Quill.register(fontSizeStyle, true);

  var Parchment = Quill.import('parchment');
    var lineHeightConfig = {
      scope: Parchment.Scope.INLINE,
      whitelist: [
        '1.0',
        '1.2',
        '1.5',
        '1.6',
        '1.8',
        '2.0',
        '2.4',
        '2.8',
        '3.0',
        '4.0',
        '5.0'
      ]
    };
    var lineHeightClass = new Parchment.Attributor.Class('lineHeight', 'ql-line-height', lineHeightConfig);
    var lineHeightStyle = new Parchment.Attributor.Style('lineHeight', 'line-height', lineHeightConfig);
    Parchment.register(lineHeightClass);
    //Quill.register({ lineHeightClass }, true);

    Parchment.register(lineHeightStyle);
    //Quill.register({ lineHeightStyle }, true);
  //import BButton from "bootstrap-vue/src/components/button/button";
  /*
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
    ['clean'],
    ['customControl']
  ];
*/
    export default {
      name: "TextEditor",
      props: {
        value: {
          type: String,
          default: ''
        },
        isEditing: {
          type: Boolean,
          default: true
        },
        color: {
          type: String,
          default: ''
        }
      },
      data(){
        return{
          content: '',
          editor: null,
          lineHeight: lineHeightConfig.whitelist,
          val: '1.0'
        }
      },
      mounted () {
        this.initQuill()
      },
      watch: {
        isEditing: function(newVal, oldVal) { // watch it
          if (newVal == false){
            this.editor.enable(false);
            //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
          }
          else{
            this.editor.enable(true);
          }
        }
      },
      beforeDestroy () {
        this.quill = null
        delete this.quill
      },
      methods: {
        initQuill () {
          this.editor = new Quill(this.$refs.editor, {
            theme: 'snow',
            modules: {
              toolbar: {
                container: this.$refs.toolbar,
                handlers: {
                  'customControl': () => {
                    console.log('customControl was clicked')
                    this.editor.insertText(this.editor.getSelection(true).index, "<sponsorhere/>");
                  }
                }
              }
            }
          })
          //this.quill = quill
          this.editor.root.innerHTML = this.value;
          this.editor.on('text-change', () => this.update());
          if (this.isEditing === false){
            this.editor.enable(false);
          }
        },
        update() {
          this.$emit('input', this.editor.getText() ? this.editor.root.innerHTML : '');
        }
      },
    }
</script>

<style scoped>
  .editor {
    height: 150px;
    transition: all .2s ease-in-out;
  }
  .ql-disabled{
    background: grey;
    opacity: .6;
    transition: all .2s ease-in-out;
    color: black
  }

/* need to put in style scss
.ql-snow .ql-picker.ql-lineHeight{
  width: 58px;
}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1.0']::before {content: '1.0';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1.0']::before {content: '1.0' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1.2']::before {content: '1.2';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1.2']::before {content: '1.2' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1.5']::before {content: '1.5';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1.5']::before {content: '1.5' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1.6']::before {content: '1.6';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1.6']::before {content: '1.6' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1.8']::before {content: '1.8';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1.8']::before {content: '1.8' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='2.0']::before {content: '2.0';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='2.0']::before {content: '2.0' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='2.4']::before {content: '2.4';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='2.4']::before {content: '2.4' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='2.8']::before {content: '2.8';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='2.8']::before {content: '2.8' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='3.0']::before {content: '3.0';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='3.0']::before {content: '3.0' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='4.0']::before {content: '4.0';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='4.0']::before {content: '4.0' !important;}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='5.0']::before {content: '5.0';}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='5.0']::before {content: '5.0' !important;}
*/
</style>